import React from 'react';
import aboutLogo from './INTHEMOOD_ABOUT_PAGE_TEXT_LOGO.jpg';

const About = () => {

    const contributors = [
        "Adrian Murray",
        "Aeon Ginsberg",
        "Alex Manley",
        "Alanna Why",
        "Ashley Obscura",
        "Bita Joudaki",
        "Brad Casey",
        "Calum Marsh",
        "Cason Sharpe",
        "Catherine Bresner",
        "Christine Shan Shan Hou",
        "Danny King",
        "Dominic Calderon",
        "Elizabeth Mudenyo",
        "EJ Kneifel",
        "Ethan Vestby",
        "Fan Wu",
        "Gabrielle Marceau",
        "Hadiyyah Kuma",
        "Kate J. Russell",
        "Kaitlin D'Avella",
        "Laura White",
        "Lily Wang",
        "Liz Bowen",
        "Maisie Jacobson",
        "Marcela Huerta",
        "MLA Chernoff",
        "Myrna Moretti",
        "Nick Newman",
        "Nicole Richardson",
        "Orchid Cugini",
        "Sanna Wani",
        "Sarah Fonseca",
        "Sarah Yanni",
        "Sennah Yee",
        "Sofia Banzhaf",
        "Sophy Romvari",
        "Tago Mago",
        "Trevor McCulloch",
        "Vannessa Barnier",
        "Will Sloan",
        "Zachary Vito"
    ];

    return(
        <section className='about'>
            <div className="wrapper">
                <section className='about__intro'>
                    <div className='about__image'>
                        <img src={aboutLogo} alt="An illustration of a cheeky-looking devil with a smoky heart halo in her room. She's smiling and showing off a paper heart cut-out craft. Her room is full of vintage TV sets, VHS tapes, and big plants."/>
                    </div>
                    <article>
                        <h1>About Us</h1>
                        <p>Welcome to In The Mood's Film Recommendation Generator! This is the sister site to <a href="https://inthemoodmagazine.com/" target='_blank' rel="noopener noreferrer">In The Mood Magazine</a>, an online pop culture journal about the things we like to watch.</p>
                        <p>What are your viewing habits, highs, and hang-ups? In other words, why do you look away and then look again? Why are you so obsessed with the exact shade of pink of Suki’s car in <span className='italic'>2 Fast 2 Furious</span>? Or the look on Marnie’s face after she’s fired the shotgun? We want to hear all about it, but you should know that we are terrible at keeping secrets.</p>
                        <p>Interested in contributing to <a href="https://inthemoodmagazine.com/" target='_blank' rel="noopener noreferrer">In The Mood Magazine</a>'s next issue? Please write to <a href='mailto:inthemoodmagazine@gmail.com'>inthemoodmagazine@gmail.com</a> and follow along on <a href="https://www.instagram.com/inthemood.magazine/" target='_blank' rel="noopener noreferrer">Instagram</a>, <a href='https://twitter.com/inthemoodmag' target='_blank' rel="noopener noreferrer">Twitter</a>, and <a href='https://inthemoodmag.substack.com/' target='_blank' rel="noopener noreferrer">Substack</a>.</p>
                        <p>So… are you in the mood?</p>
                    </article>
                </section>
                <section className='about__credits'>
                    <h2>Created By</h2>
                        <p className='pink'>Gabrielle Marceau</p>
                        <p className='pink'>Sennah Yee</p>
                </section>
                <section className='about__credits about__credits--contrib'>
                    <h2>Contributors</h2>
                    <ul>
                        {
                            contributors.map((contributor, idx)=>{
                                return(
                                    <li key={idx}><p className='pink'>{contributor}</p></li>
                                )
                            })
                        }
                    </ul>
                </section>
                <section className='about__credits'>
                    <h2>Site Credits</h2>
                    <ul>
                        <li><p className='pink'>Web Design & Development by <a href='http://kaystocks.com/' target='_blank' rel="noopener noreferrer" className='pink-link'>Kay Evans-Stocks</a></p></li>
                        <li><p className='pink'>Illustrations by <a href='https://linktr.ee/pizzatimecomics' target='_blank' rel="noopener noreferrer" className='pink-link'>Aidan Jeans</a></p></li>
                        <li><p className='pink'>Logo by <a href='https://marcelahuerta.com/' target='_blank' rel="noopener noreferrer" className='pink-link'>Marcela Huerta</a></p></li>
                    </ul>
                </section>
            </div>
        </section>
    )
}

export default About;