import React, { useEffect , useState } from 'react';

const Result = ({ film, tier, starterMood, changeMoodTiers}) => {

    const [isLoaded, setIsLoaded ] = useState(false);

    // function to handle the mood change and tier
    const handleChangeMood = (newTier, newMood) => {
        changeMoodTiers(newTier, newMood);
    }

    useEffect(()=>{
        // scroll to top each time
        window.scrollTo(0, 0);
    }, [])

    return(
        <section className='results'>
            <div className="wrapper">
                <div className='results__top-button'>
                        <button type="button" className='pink b-pink' onClick={()=>{handleChangeMood(tier, starterMood)}}>Go back</button>
                </div>
                <div className='results__image'>
                    <img src={isLoaded ? `/assets/${film.image}` : `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${film.width} ${film.height}"%3E%3C/svg%3E`} alt={film.imageAlt} onLoad={()=> setIsLoaded(true)}/>
                </div>
                <h1 className='results__title'>If you're feeling {film.mood}, {film.author} recommends</h1>
                <h2 className='results__film-name elegant' title={film.name}>{film.name}</h2>
                <p className='results__director'>{film.director}, {film.year}</p>
                <div className='results__content' dangerouslySetInnerHTML={{__html: film.content}} />
                <h3 className='results__bio pink' dangerouslySetInnerHTML={{__html: film.authorBio, }}/>
                <div className='results__button'>
                    <button type="button" className='pink b-main' onClick={()=>{handleChangeMood(1, null)}}>Try again</button>
                </div>
            </div>
        </section>
    )
}

export default Result;
