import React, { useState, useRef, useEffect } from 'react';
import mainLogo from './IntheMood_LOGO_White.png';

const Header = ({ displayAbout, changeMoodTiers }) => {

    const [isInstructions, setIsInstructions] = useState(false);
    const header = useRef(null);

    useEffect(()=>{
        header.current.focus();
    }, [])

    return (
        <header tabIndex='0' ref={header}>
            <div className="wrapper">
                <section>
                    <button className='header__image' onClick={()=>{changeMoodTiers(1, null)}}>
                        <img src={mainLogo} alt="In The Mood home"/>
                    </button>
                    <p className='pink'>Film Recommendation Generator</p>
                </section>
                <nav>
                    <ul>
                        <li>
                            <button type="button" className='pink pink-link' onClick={()=>{changeMoodTiers(1, null)}}>Home</button>
                        </li>
                        <li>
                            <button type="button" className='pink pink-link' onClick={displayAbout}>About</button>
                        </li>
                        <li>
                            <button type="button" className='header__question' aria-label='Hover to view instruction' onClick={()=> setIsInstructions(!isInstructions)}><p aria-hidden='true'>?</p></button>
                            {
                                isInstructions && <div className='header__instructions'>
                                    <p>What are you in the mood for? Pick a mood and we’ll recommend a film to watch.</p>
                                </div> 
                            }
                            
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header;