import React, { useEffect, useState, useCallback } from 'react';
import introImg from './TRANSPARENT_intro_image_02.png';
import Happy from './01_HAPPY.png';
import Glamorous from './02_GLAMOROUS.png';
import Powerful from './03_POWERFUL.png';
import Loving from './04_LOVING.png';
import Gluttonous from './05_GLUTTONOUS.png';
import Trippy from './06_TRIPPY.png';
import Sad from './01_SAD.png';
import Ashamed from './02_ASHAMED.png';
import Lonely from './03_LONELY.png';
import Depressed from './04_DEPRESSED.png';
import Disillusioned from './04_DISILLUSIONED.png';
import Somber from './05_SOMBER.png';
import Empty from './06_EMPTY.png';
import Devastated from './07_DEVASTATED.png';
import Forlorn from './08_FOLORN.png';
import OutofSorts from './09_OUT-OF-SORTS.png';
import Angry from './01_ANGRY.png';
import Tough from './02_TOUGH.png';
import Incandescent from './03_INCANDESCENT.png';
import Passionate from './04_PASSIONATE.png';
import Grumpy from './5_GRUMPY.png';
import RiledUp from './06_RILED-UP.png';
import Anxious from './01_ANXIOUS.png';
import Overwhelmed from './02_OVERWHELMED.png';
import Fragile from './03_FRAGILE.png';
import Paranoid from './04_PARANOID.png';
import Chaotic from './05_CHAOTIC.png';
import Thirsty from './06_THIRSTY.png';
import Yearning from './07_YEARNING.png';
import Manic from './08_MANIC.png';
import Uneasy from './09_UNEASY.png';
import Neutral from './01_NEUTRAL.png';
import Apathetic from './04_APATHETIC.png';
import Anodyne from './02_ANODYNE.png';
import Detached from './03_DETATCHED.png';

const Buttons = ({ position, buttonText, buttonFunc })=>{
    return(
        <div className={`tier__${position}-button`}>
            <button type="button" onClick={buttonFunc} className='pink b-pink'>{buttonText}</button>
        </div>
    )
}

const Tiers = ({ starterMood, moods, films, tier, changeMoodTiers=false, displayResult=false, hasSecondTier }) => {

    const [imageSrc, setImageSrc] = useState(null);

    // function to set background
    const handleImage = useCallback((valToSearch)=>{
        const mascotImages = [
            {
                name: 'Happy',
                src: Happy
            },
            {
                name: 'Glamorous',
                src: Glamorous,
            },
            {
                name: 'Powerful',
                src: Powerful,
            },
            {
                name: 'Loving',
                src: Loving,
            },
            {
                name: 'Gluttonous',
                src: Gluttonous,
            },
            {
                name: 'Trippy',
                src: Trippy,
            },
            {
                name: 'Sad',
                src: Sad,
            },
            {
                name: 'Ashamed',
                src: Ashamed,
            },
            {
                name: 'Lonely',
                src: Lonely,
            },
            {
                name: 'Depressed',
                src: Depressed,
            },
            {
                name: 'Disillusioned',
                src: Disillusioned,
            },
            {
                name: 'Somber',
                src: Somber,
            },
            {
                name: 'Empty',
                src: Empty,
            },
            {
                name: 'Devastated',
                src: Devastated,
            },
            {
                name: 'Forlorn',
                src: Forlorn,
            },
            {
                name: 'Out of Sorts',
                src: OutofSorts,
            },
            {
                name: 'Angry',
                src: Angry,
            },
            {
                name: 'Tough',
                src: Tough,
            },
            {
                name: 'Incandescent',
                src: Incandescent,
            },
            {
                name: 'Passionate',
                src: Passionate,
            },
            {
                name: 'Grumpy',
                src: Grumpy,
            },
            {
                name: 'Riled up',
                src: RiledUp,
            },
            {
                name: 'Anxious',
                src: Anxious,
            },
            {
                name: 'Overwhelmed',
                src: Overwhelmed,
            },
            {
                name: 'Fragile',
                src: Fragile,
            },
            {
                name: 'Paranoid',
                src: Paranoid,
            },
            {
                name: 'Chaotic',
                src: Chaotic,
            },
            {
                name: 'Thirsty',
                src: Thirsty,
            },
            {
                name: 'Yearning',
                src: Yearning,
            },
            {
                name: 'Manic',
                src: Manic,
            },
            {
                name: 'Uneasy',
                src: Uneasy,
            },
            {
                name: 'Alienated',
                src: Detached,
            },
            {
                name: 'Neutral',
                src: Neutral,
            },
            {
                name: 'Apathetic',
                src: Apathetic,
            },
            {
                name: 'Anodyne',
                src: Anodyne,
            },
            {
                name: 'Detached',
                src: Detached,
            },
            {
                name: 'Indulgent',
                src: Gluttonous,
            },
            {
                name: 'Vapid',
                src: Neutral,
            },
            {
                name: 'Tranquillized',
                src: Neutral,
            },
            {
                name: 'Safe',
                src: Neutral,
            },
            {
                name: 'Horny',
                src: Neutral,
            },
            {
                name: 'Bored',
                src: Neutral,
            },
            {
                name: 'Curious',
                src: Neutral,
            },
            {
                name: 'Skeptical',
                src: Neutral,
            },
            {
                name: 'Jealous',
                src: Anxious,
            }, 
            {
                name: 'Eager',
                src: Happy,
            },
            {
                name: 'Euphoric',
                src: Happy,
            },
            {
                name: 'Silly',
                src: Happy,
            },
            {
                name: 'Audacious',
                src: Happy,
            }
        ]
        mascotImages.forEach((imageSet)=>{
            if(valToSearch === imageSet.name){
                setImageSrc(imageSet.src);
            }
        })
    }, [])

    useEffect(()=>{
        // scroll to top each time
        window.scrollTo(0, 0);
        handleImage(starterMood);
    }, [starterMood, tier, handleImage])
    

    // define new tier to send back up
    let newTier;
    if(tier === 1){
        newTier = 2;
    } else if (tier === 2){
        newTier = 3;
    }

    // function to handle the mood change and tier
    const handleChangeMood = (newTier, newMood) => {
        document.activeElement.blur();
        changeMoodTiers(newTier, newMood);
    }

    // function to show result
    const handleShowResult = (moodToSearch) => {
        displayResult(moodToSearch);
    }

    // function to change background
    const handleChangeBackground = (e) => {
        handleImage(e.target.value);
    }

    // when it is the first tier, wnat function to change the starter mood and the tier to show on BUTTON click
    // when it is the second tier, or third tier, want function to show result on BUTTON click
    
    return(
        <section>
            <div className="wrapper">
                {   tier === 2  ? <Buttons position={'top'} buttonText={'Go back'} buttonFunc={()=>handleChangeMood(1, null)} /> : tier === 3 ?  <Buttons position={'top'} buttonText={'Go back'} buttonFunc={()=>handleChangeMood(2, starterMood)} /> : null }
            </div>
            <section className='tier'>
                <div className="wrapper">
                    <p className='pink'>STEP {tier}{ tier !== 1 ? ` - ${starterMood}` : null}</p>
                    <h1 className='white-elegant elegant'>I'm feeling ... </h1>
                    <ul className='moods'>
                        {
                            moods.map((mood, idx)=>{
                                return(
                                <li key={mood}><button type="button" className="b-main" value={mood} onMouseEnter={ (tier === 2 | tier === 3) ? handleChangeBackground : null} onMouseLeave={ (tier === 2 | tier === 3) ? () => handleImage(starterMood) : null} onClick={ tier === 1 ? ()=> {handleChangeMood(newTier, mood)}: tier === 2 || tier === 3 ? ()=> {handleShowResult(mood)} : null }>{mood}</button></li>
                                )
                            })
                        }
                    </ul>
                    <div className={ (tier === 2 | tier === 3) ? `tier__mascot tier__mascot--side`: `tier__mascot`}>
                        {
                            tier === 1 ? <img src={introImg} alt="The mascot in seductive pose"/> : null
                        }
                        {
                            (tier === 2 | tier === 3) ? <img src={imageSrc} alt="The mascot in specific pose for the hovered mood"/> : null
                        }
                    </div>
                    {   (tier === 2 && hasSecondTier)  ? <Buttons position={'bottom'} buttonText={'More moods'} buttonFunc={()=>handleChangeMood(3, starterMood)} key={'More moods'}/> 
                        : tier === 3 ? <Buttons position={'bottom'} buttonText={'Restart'} buttonFunc={()=>handleChangeMood(1, null)} key={'Restart'}/> 
                        : null 
                    }
                </div>
            </section>
        </section>
    )

}

export default Tiers;