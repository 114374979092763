import firebase from 'firebase/app';
import 'firebase/database';

const config = {
	apiKey: "AIzaSyBuWn0uYZs8zgBuM95tKC_xVhaAFpechmk",
    authDomain: "inthemoodapp-647c3.firebaseapp.com",
    databaseURL: "https://inthemoodapp-647c3.firebaseio.com",
    projectId: "inthemoodapp-647c3",
    storageBucket: "inthemoodapp-647c3.appspot.com",
    messagingSenderId: "156001845135",
    appId: "1:156001845135:web:37881b2f4f0be24a3955fa"
};

firebase.initializeApp(config);

export default firebase;