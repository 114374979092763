import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = ()=>{
    return(
        <footer>
            <div className="wrapper">
                <small>ALL RIGHTS RESERVED TO <a href='https://www.inthemoodmagazine.com/'>IN THE MOOD MAGAZINE</a></small>
                <ul>
                    <li>
                        <a href="https://www.instagram.com/inthemood.magazine/" target='_blank' rel="noopener noreferrer" aria-label="Click to go to Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
                    </li>
                    <li>
                        <a href='https://twitter.com/inthemoodmag' target='_blank' rel="noopener noreferrer" aria-label="Click to go to Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
                    </li>
                    <li>
                        <a href='https://inthemoodmag.substack.com/' target='_blank' rel="noopener noreferrer" aria-label="Click to go to Substack"><FontAwesomeIcon icon={faNewspaper}/></a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;