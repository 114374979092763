import React from 'react';
import mainLogo from './IntheMood_LOGO_White.png';

const Landing = ({ hideLanding })=>{
    return(
        <section className="landing-page">
            <div className="wrapper">
                <div className="landing-page__logo">
                    <img src={mainLogo} alt="In The Mood logo" title='In The Mood'/>
                </div>
                <h1 className="pink">Film Recommendation Generator</h1>
                <button className="b-main" onClick={hideLanding}>Start</button>
            </div>
        </section>
    )
}

export default Landing;