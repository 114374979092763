import React, { useState, useEffect, useCallback } from 'react';
import About from './About';
import './App.scss';
import firebase from './firebase';
import Footer from './Footer';
import Header from './Header';
import Landing from './Landing';
import Result from './Result';
import Tiers from './Tiers';

const App = () => {
  const [moods, setMoods] = useState([]);
  const [films, setFilms] = useState([]);
  const [moodsToShow, setMoodsToShow] = useState([]);
  const [tier, setTier] = useState(1);
  const [starterMood, setStarterMood] = useState(null);
  const [isLanding, setIsLanding] = useState(true);
  const [isTiers, setIsTiers] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const [selectedFilm, setSelectedFilm] = useState(null);
  const [hasSecondTier, setHasSecondTier] = useState(false);

  
   // make a function to set the moods
  const showTheMoods = useCallback(() => {
    let moodsToSet = [];
    // set the current moods to show
    if(tier === 1){
        moodsToSet = moods.map((begMood)=>{
            return begMood.name;
        })
    } else if(starterMood && tier === 2 ){
        let newMoods = moods.filter((mood) => {
            return mood.name === starterMood;
        })

        newMoods[0].tierOne.forEach((mood)=>{
          moodsToSet.push(mood);
        })

        if(newMoods[0].tierTwo === false){
          setHasSecondTier(false);
        } else {
          setHasSecondTier(true);
        }

    } else if(starterMood && tier === 3 ){
      let newMoods = moods.filter((mood) => {
        return mood.name === starterMood;
      })

      if(newMoods[0].tierTwo){
        newMoods[0].tierTwo.forEach((mood)=>{
          moodsToSet.push(mood);
        })
      } else {
        console.log('no second tier ;/')
      }
    }
    
    // set it once
    setMoodsToShow(moodsToSet);
  }, [moods, starterMood, tier]);

  // on mount get data from firebase
  useEffect(() => {
    // our database reference
    const dbRef = firebase.database().ref();

    // get the values from firebase
    dbRef.on('value', (response) => {

      // capture the films in state
      setFilms(response.val().films);
      
      // capture the starter moods in state
      setMoods(response.val().moods);
    });
  }, []);

  // when moods, films updates, show diff moods
  useEffect(()=>{
    showTheMoods();
  }, [moods, films, starterMood, tier, showTheMoods])


  // hide landing page
  const hideLanding = () => {

    // hide the landing page
    setIsLanding(false);

    // set the new tier to the first one
    setTier(1);

    // show the tiers
    setIsTiers(true);
  }

  // function to change the tier and the starter mood on click in Tiers
  const changeMoodTiers = (newTier, newMood)=> {

    // set the new tier
    setTier(newTier);

    // set the starter mood
    setStarterMood(newMood);

    // hide results
    setIsResult(false);

    // hide about
    setIsAbout(false);

    // show tier
    setIsTiers(true);
  }

  // function to show the results
  const displayResult = ( moodToSearch ) => {
    // take the mood to search, 
    
    // filter through films, find film that matches
    let filmToSet = films.filter((film) => film.mood === moodToSearch);

    // if no match console, no match
    if (filmToSet[0] === undefined){
      console.log('No film yet!');
    } else {
      // set the selected film
      setSelectedFilm(filmToSet[0]);
  
      // hide tiers
      setIsTiers(false);
  
      // show result
      setIsResult(true);
    }
  }

  // function to show about page
  const displayAbout = () => {
    // hide tiers
    setIsTiers(false);

    // hide results
    setIsResult(false);

    // show about
    setIsAbout(true);
  }

  return (
    <div>
    { !isLanding ? <Header displayAbout={displayAbout} changeMoodTiers={changeMoodTiers}/> : null}
      <main>
          { isLanding ? <Landing hideLanding={hideLanding} /> : null}
          { isTiers ? <Tiers starterMood={starterMood} moods={moodsToShow} films={films} tier={tier} changeMoodTiers={changeMoodTiers} displayResult={displayResult} hasSecondTier={hasSecondTier}/> : null}
          { isResult ? <Result film={selectedFilm} tier={tier} starterMood={starterMood} changeMoodTiers={changeMoodTiers}/> : null }
          { isAbout ? <About /> : null }
      </main>
    { !isLanding ? <Footer /> : null}
    </div>
  );
}

export default App;
